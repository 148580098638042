export default class Brand {
    brandID: string
    brandName: string
    brandCode: string
    companyID: string
    companyName: string
    timeZone: string
    isDeleted: boolean

    constructor(brandID: string, brandName: string,brandCode: string, companyID: string, timeZone: string, isDeleted: number, companyName: string = '') {
        this.brandID = brandID
        this.brandName = brandName
        this.brandCode = brandCode
        this.companyID = companyID
        this.companyName = companyName
        this.timeZone = timeZone
        this.isDeleted = isDeleted === 1
    }
}